<template>
  <b-card class="card-congratulation-medal h-90">
    <b-card-header
      class="pl-0 pt-0"
    >
      <div>
        <b-card-title>Account Settings</b-card-title>
      </div>
    </b-card-header>
    <b-card-body class="pl-0">
      <div
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-img
              :src="currentUser.image ? currentUser.image: require('@/assets/images/simiicons/Insideicon.svg') "
              height="80"
              width="80"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <div class="text-truncate emailDiv">
              <feather-icon
                icon="UserIcon"
                class="icon"
              />
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="currentUser.name"
                class="mb-0 profileText"
              >
                {{ currentUser.name }}
              </span>
            </div>
            <div class="text-truncate emailDiv">
              <feather-icon
                icon="MailIcon"
                class="icon"
              />
              <small
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="currentUser.email"
                class="pl-0 profileText"
              > {{ currentUser.email }}</small>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="viewSettings"
    >
      View Account
    </b-button>
    <b-img
      :src="require('@/assets/images/simiicons/Intersection 1.png')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCardBody,
  BCard, BButton, BImg, BCardHeader, BMediaBody, BMediaAside, BCardTitle, BMedia, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BCardBody,
    BCardHeader,
    BMediaBody,
    BMediaAside,
    BCardTitle,
    BMedia,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    viewSettings() {
      this.$router.push('/account-setting')
    },
  },
}
</script>
<style scoped>
.profileText{
  padding-left: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #383838;
  font-weight: 400;
  font-family: "Montserrat";
}
.icon{
  width: 20px;
  height: 20px;
  padding-right: 5px;
  color: black;
}
.emailDiv{
  max-width: 180px
}
</style>
