<template>
  <b-card
    text-variant="center"
    class="card card-congratulations h-90"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/simiicons/right.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/simiicons/left.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->
    <b-img
      size="28"
      :src="require('@/assets/images/simiicons/Hand.svg')"
    />
    <h1 class="mb-1 mt-50 text-white pt-1">
      Welcome Back
    </h1>
    <b-card-text class="m-auto w-75 text text-white">
      You have done <strong>57.6%</strong> more sales today. Check your new badge in your profile.
    </b-card-text>
  </b-card>

</template>

<script>
import {
  BCard, BCardText, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,

    BImg,
    BCardText,
  },
}
</script>
<style scoped>
.text{
  padding-bottom: 5px;
}
</style>
