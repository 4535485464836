<template>
  <div class=" h-100">
    <b-card
      :img-src="require('@/assets/images/simiicons/ImgBack.png')"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile h-100"
    >
      <b-skeleton-wrapper
        :loading="loadingProfile"
        class="pl-2 pr-2"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <div class="profile-image-wrapper ">
          <div class="profile-image p-0">
            <b-skeleton-wrapper :loading="loadingProfile">
              <template #loading>
                <b-skeleton
                  width="80px"
                  height="80px"
                  type="avatar"
                />
              </template>
              <b-avatar
                size="114"
                variant="light"
                :src="defaultProfileData.image "
              />
            </b-skeleton-wrapper>
          </div>
        </div>
        <Span
          class="text-wrap font-weight-bold"
          style="font-size: medium"
        >{{ defaultProfileData.name }}</Span>
        <h6 class="text-muted mt-1">
          Default
        </h6>
        <b-badge
          class="profile-badge badge"
          variant="light-success"
        >
          Active
          <!-- {{ defaultProfileData.account_status ? 'Active' : 'Not Active' }} -->
        </b-badge>
        <div class="px-1">
          <hr class="mb-2">
        </div>
        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="w-100">
            <h6 class="text-muted font-weight-bold apps">
              Allowed Apps
            </h6>
            <h6
              v-if="defaultProfileData.allowed_apps"
              class="mb-0"
            >
              {{ defaultProfileData.allowed_apps.length }}
            </h6>
          </div>
          <div class="w-100">
            <h6 class="text-muted font-weight-bold apps">
              Allowed Widgets
            </h6>
            <h6
              v-if="defaultProfileData.widgets"
              class="mb-0"
            >
              {{ defaultProfileData.widgets.length }}
            </h6>
          </div>
          <div class="w-75">
            <h6 class="text-muted font-weight-bold apps ">
              Views
            </h6>
            <h6 class="mb-0">
              3
            </h6>
          </div>
        </div>
      </b-skeleton-wrapper>
      <!--/ follower projects rank -->
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BAvatar, BBadge, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BSkeleton,
    BSkeletonWrapper,
    // BCardBody,
  },
  data() {
    return {
      defaultProfileData: '',
      loadingProfile: false,
    }
  },
  async mounted() {
    await this.defaultProfile()
  },
  methods: {
    async defaultProfile() {
      this.loadingProfile = true
      return this.$axios
        .get('user/profile/default-profiles')
        .then(({ data }) => {
          this.loadingProfile = false
          const { data: dataRes } = data || {}
          this.defaultProfileData = dataRes
        }).catch(error => ({ error }))
    },
  },
}
</script>
<style scoped>
.badge{
  border-radius: 25px;
  padding-inline: 10px;
}
.apps{
font-size: 12px;
color: #b9b9c3;
font-weight: 600;
font-family: "Montserrat";
text-align: center;
}
.card-body{
 padding-inline: 0px !important;
 /* padding-left: 21px !important;
 padding-right: 21px !important; */
}
.card-profile{
  margin-bottom: 0;
}
</style>
