<template>
  <div>
    <b-row>
      <b-col
        lg="7"
        md="6"
      >
        <CardCongratulation />
      </b-col>
      <b-col
        lg="5"
        md="6"
      >
        <card-account-settings />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="4"
        md="6"
      >
        <CardProfile />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <CardBannerAds />
      </b-col>
      <b-col
        lg="4"
        md="6"
        class="mt-2 mt-md-0"
      >
        <CardMediaStorage />
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardCongratulation from '@/common/components/CardCongratulation.vue'
import CardMediaStorage from '@/components/mediastorage/CardMediaStorage.vue'
import CardBannerAds from '@/components/bannerads/CardBannerAds.vue'
import CardProfile from '@/components/profiles/CardProfile.vue'
import CardAccountSettings from '@/components/account-setting/CardAccountSettings.vue'

export default {
  components: {
    BRow,
    BCol,

    CardCongratulation,
    CardMediaStorage,
    CardBannerAds,
    CardProfile,
    CardAccountSettings,
  },
}
</script>
